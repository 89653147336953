import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";
import store from "../store";

export class PrescriptionService extends BaseService {
  static async getDoctorPrescription(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `/prescriptionorder?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async checkPrescriptionOrder(patientid, prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/check`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getDosepotPrescription(patientid, prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/dosespotmedication`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPrescriptionOrder(patientid, prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/prescriptionorder/${patientid}/${prescriptionorderid}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchPrescriptionOrder(search) {
    try {
      const response = await this.request({ auth: true }).get(
        `/prescriptionorder/search?contains=${search}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchPrescriptionOrderPerPatient({ search, patientid }) {
    try {
      const response = await this.request({ auth: true }).get(
        `/prescriptionorder/${patientid}/search?contains=${search}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPrescriptionOrderPerPatient(patientid, nextpage) {
    try {
      if (nextpage === "all") {
        const response = await this.request({ auth: true, v2: true }).get(
          `/prescriptionOrder/full/${patientid}`,
          {
            headers: {
              ...(store.state.globals.currentDoctor.id && {
                doctorid: store.state.globals.currentDoctor.id,
              }),
            },
          }
        );
        return new ResponseWrapper(response, response.data);
      } else {
        const response = await this.request({ auth: true }).get(
          `/prescriptionorder/${patientid}?nextpage=${nextpage}`,
          {
            headers: {
              ...(store.state.globals.currentDoctor.id && {
                doctorid: store.state.globals.currentDoctor.id,
              }),
            },
          }
        );
        return new ResponseWrapper(response, response.data.body);
      }
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPrescriptionOrderProducts(patientid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/prescriptionorder/${patientid}/product`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getProductDosageAndUnit(patientid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/product/patient/${patientid}/dosage`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addPrescriptionOrder(patientid, data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/prescriptionorder/${patientid}`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updatePrescriptionOrder(patientid, prescriptionorderid, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async deletePrescriptionOrder(patientid, prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/prescriptionorder/${patientid}/${prescriptionorderid}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async prescribePrescriptionOrder(patientid, prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/prescribe`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addMultiplePrescriptionOrder(patientid, data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/prescriptionorder/${patientid}/multiple`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addMultiplePrescriptionOrderV2(patientid, data) {
    try {
      const response = await this.request({ auth: true, v4: true }).post(
        `/prescriptionorder/${patientid}/multiple?prescriptionversion=V2`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async generateDospatURL(patientid) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/dosespot/patient/${patientid}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async sendPrescriptionOrderConfirmation(
    patientid,
    prescriptionorderid
  ) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/sendconfirmation`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async cancelPrescriptionOrder(prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true, v2: true }).delete(
        `/prescriptionOrder/${prescriptionorderid}/cancel`,
        {
          data: {
            cancelfuturerefill: payload.cancelfuturerefill,
            cancellationnotes: payload.cancellationnotes,
            cancellationreason: payload.cancellationreason,
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async cancelRefundPrescriptionOrder(prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/transact/prescriptionorder/${prescriptionorderid}/cancel`,
        {
          data: {
            ...(payload.hasOwnProperty("refund") && { refund: payload.refund }),
            cancellationnotes: payload.cancellationnotes,
            cancellationreason: payload.cancellationreason,
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async stopPrescription(patientid, prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/stop`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async continuePrescription(patientid, prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).post(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/continue`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async retryPrescription(prescriptionorderid) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/transact/prescriptionorder/${prescriptionorderid}/retrypayment`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async pausePrescription(prescriptionorderid, data) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/prescriptionOrder/${prescriptionorderid}/pause`,
        data
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async resumePrescription(prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/prescriptionorder/${prescriptionorderid}/resume`,
        payload
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async ignorePrescription(patientid, prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/ignore`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async ignoreFollowup(patientid, patientfollowupid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/patient/${patientid}/followupschedule/${patientfollowupid}/ignore`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async markFollowupComplete({
    patientid,
    ids,
    followupactionnotes,
    followupactionid,
  }) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/doctor/patientFollowup/${patientid}/markAsComplete`,
        {
          ids,
          followupactionnotes,
          followupactionid,
        },
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
            "Content-Type": "application/json",
          },
        }
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async stopDelay(patientid, prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/stopdelay`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async rushDelayTimer(prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/prescriptionorder/${prescriptionorderid}/bypassdelay`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async bypassDelay(prescriptionorderid) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/transact/prescriptionorder/${prescriptionorderid}/sendorder`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async cancelPendingOrder(prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/transact/prescriptionorder/${prescriptionorderid}/cancelorder`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async payNextInvoice(prescriptionorderid) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/transact/prescriptionorder/${prescriptionorderid}/paynextinvoice`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addRefill(patientid, prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/refill`,
        payload
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateShipmentDate(patientid, prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/update`,
        payload
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateTreatmentDate(patientid, prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/updatetreatmentstart`,
        payload
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async restartTreatment(patientid, prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/restart`,
        payload
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async schedulePrescription(patientid, prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/schedule`,
        payload
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
